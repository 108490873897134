/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'

// material-ui
import { Box, OutlinedInput, FormControl } from '@mui/material'

// project imports
import useCurrentUser from 'hooks/useCurrentUser'
import useSetState from 'hooks/useSetState'
import { parseValueByType } from 'utils/utilities'

// ==============================|| DATE TIME COMPONENT ||============================== //

const defaultState = {
  contentOnChange: '',
}

const DateTimeComponent = (props) => {
  const {
    callbacks: { toggleSetValue },
    part: { detail },
  } = props

  const [state, setState] = useSetState(defaultState)
  const { contentOnChange } = state

  const { displayTimeZone } = useCurrentUser()

  useEffect(() => {
    setState({ contentOnChange: detail?.value || '' })
    if (detail?.value && detail?.value !== '') {
      toggleSetValue(parseValueByType(detail?.value, detail?.sub_type))
    }
  }, [detail?.value, detail])

  const dateToday = DateTime.now().toFormat('yyyy-MM-dd') + 'T00:00'

  const updateDateTime = (value) => {
    setState({ contentOnChange: value })

    toggleSetValue(parseValueByType(value, detail?.sub_type))
  }

  return (
    <Box noValidate>
      <FormControl fullWidth>
        <OutlinedInput
          inputProps={{ min: dateToday }}
          onChange={(e) => updateDateTime(e.target.value)}
          placeholder={detail?.display_name}
          type="datetime-local"
          value={
            contentOnChange
              ? DateTime.fromISO(contentOnChange, { zone: displayTimeZone }).toFormat('yyyy-MM-dd') +
                'T' +
                DateTime.fromISO(contentOnChange, { zone: displayTimeZone }).toFormat('T')
              : ''
          }
        />
      </FormControl>
    </Box>
  )
}

DateTimeComponent.propTypes = {
  callbacks: PropTypes.object,
  part: PropTypes.object,
}

export default DateTimeComponent
