import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, Link, List, ListItem, Typography } from '@mui/material'
import { IconExternalLink } from '@tabler/icons'

// project imports
import CollapsibleListItem from 'ui-component/CollapsibleListItem'

// ==============================|| STATIC COMPONENT ||============================== //

const StaticComponent = (props) => {
  const theme = useTheme()
  const {
    part: { detail },
  } = props

  if (detail.constant?.client_ui?.flags?.includes('DisplayLink')) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', color: theme.palette.blue.light }}>
        <Link target="_blank" href={detail.value} underline="hover" sx={{ color: theme.palette.blue.light, cursor: 'pointer', mr: 0.5 }}>
          <Typography>{detail.display_name}</Typography>
        </Link>
        <IconExternalLink size="0.9rem" />
      </Box>
    )
  }

  return (
    <CollapsibleListItem
      forceOpen={!detail.constant?.client_ui?.flags?.includes('CollapseDefault')}
      paddingX={0}
      primaryNode={
        <Typography variant="h5" sx={{ fontWeight: 500 }}>
          {detail?.display_name || 'Part Name'}
        </Typography>
      }
      reverse
    >
      {detail?.type !== 'List' ? (
        <Typography sx={{ whiteSpace: 'pre-wrap' }}>{detail?.value}</Typography>
      ) : (
        <List sx={{ listStyleType: 'disc', pl: 2, py: 0 }}>
          {detail?.value?.map((value) => (
            <ListItem key={value} sx={{ display: 'list-item', pt: 0 }}>
              <Typography>{value}</Typography>
            </ListItem>
          ))}
        </List>
      )}
    </CollapsibleListItem>
  )
}

StaticComponent.propTypes = {
  callbacks: PropTypes.object,
  part: PropTypes.object,
}

export default StaticComponent
