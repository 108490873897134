import * as assets from './asset'
import * as currentUser from './currentUser'
import * as entities from '../entities'
import * as favourites from './favourite'
import * as jobs from './job'
import * as merchandiseOrderItems from './merchandiseOrderItem'
import * as merchandises from './merchandise'
import * as offices from './office'
import * as orderItems from './orderItem'
import * as orders from './order'
import * as posts from './post'
import * as prices from './price'
import * as productParts from './productPart'
import * as products from './product'
import * as projectFiles from './projectFile'
import * as projectSlots from './projectSlot'
import * as projectTemplates from './projectTemplate'
import * as projects from './project'
import * as projectsPlatform from './projectPlatform'
import * as projectsSearch from './projectSearch'
import * as providers from './provider'
import * as tasks from './task'
import * as taskSheets from './taskSheet'
import * as users from './user'
import * as zoneProvidersEstimates from './zoneProvidersEstimate'

import * as enums from './enum'

const modules = {
  assets,
  currentUser,
  entities,
  enums,
  favourites,
  jobs,
  merchandiseOrderItems,
  merchandises,
  offices,
  orderItems,
  orders,
  posts,
  prices,
  productParts,
  products,
  projectFiles,
  projectSlots,
  projectTemplates,
  projects,
  projectsPlatform,
  projectsSearch,
  providers,
  tasks,
  taskSheets,
  users,
  zoneProvidersEstimates,
}

export const reducers = Object.keys(modules).reduce((acc, moduleKey) => {
  const module = modules[moduleKey]
  acc[moduleKey] = module.default

  return acc
}, {})

export default modules
