/* eslint-disable no-process-env */

const apimUrl = 'https://apim-ch-api.azure-api.net'
const aadb2cAuthorityDomain = 'contenthouseio.b2clogin.com'

const envVars = {
  staging: {
    aadb2cUserFlow: 'B2C_1_SSO_staging',
    bookingWorkflowId: '1bcd672a-71f1-477b-9278-4ecef68e876b',
    cms1Url: 'https://staging-cms.contenthouse.io',
    defaultProjectParts: '52, 189, 190, 191, 192, 193, 194',
    imageUrl: `${apimUrl}/workbase/images;rev=2`,
    meUrl: `${apimUrl}/workbase/me;rev=2`,
    utilitiesUrl: `${apimUrl}/workbase/utilities;rev=2`,
    workbaseUrl: `${apimUrl}/workbase;rev=2`,
    workbaseV2Url: 'https://func-ch-staging-backend-workbase.azurewebsites.net/api',
    rhProjectReferenceUrl: 'https://realhub-frontend.staging.realbase.io/#/campaigns',
    ctProjectReferenceUrl: 'https://azure-stage.campaigntrack.com/CtCampaignDashboard/?PropertyNo',
  },
  production: {
    aadb2cUserFlow: 'B2C_1_SSO',
    bookingWorkflowId: '22543208-09fb-42d8-abb3-c11321432df4',
    cms1Url: 'https://cms.contenthouse.io',
    defaultProjectParts: '2, 28, 29, 30, 31, 32, 33',
    imageUrl: `${apimUrl}/workbase/images`,
    meUrl: `${apimUrl}/workbase/me`,
    utilitiesUrl: `${apimUrl}/workbase/utilities`,
    workbaseUrl: `${apimUrl}/workbase`,
    workbaseV2Url: 'https://func-ch-backend-workbase.azurewebsites.net/api',
    rhProjectReferenceUrl: 'https://realhub-frontend.realbase.io/#/campaigns',
    ctProjectReferenceUrl: 'https://live.campaigntrack.com/CtCampaignDashboard/?PropertyNo',
  },
}

const {
  aadb2cUserFlow,
  bookingWorkflowId,
  cms1Url,
  ctProjectReferenceUrl,
  defaultProjectParts,
  imageUrl,
  meUrl,
  rhProjectReferenceUrl,
  utilitiesUrl,
  workbaseUrl,
  workbaseV2Url,
} = envVars[process.env.REACT_APP_APPLICATION_ENV]

const config = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
  // like '/cms2-contenthouse/react/default'
  aadb2cApiEndpoint: `${workbaseUrl}/users`,
  aadb2cApiScopes: process.env.REACT_APP_AADB2C_API_SCOPES,
  aadb2cAuthorityDomain,
  aadb2cClientId: process.env.REACT_APP_AADB2C_CLIENT_ID,
  aadb2cUserFlow,
  aadb2cUserFlowAuthority: `https://${aadb2cAuthorityDomain}/tfp/contenthouseio.onmicrosoft.com/${aadb2cUserFlow}`,
  apimKey: process.env.REACT_APP_APIM_KEY,
  basename: '/',
  bookingWorkflowId,
  borderRadius: 12,
  cms1Url,
  ctProjectReferenceUrl,
  defaultPath: '/',
  defaultProjectParts,
  fontFamily: `'Roboto', sans-serif`,
  googleComponentRestrictions: { country: 'au' },
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  imageUrl,
  meUrl,
  rhProjectReferenceUrl,
  utilitiesUrl,
  workbaseUrl,
  workbaseV2Url,
}

export default config
