/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'

// material-ui
import { Box, OutlinedInput, FormControl, InputAdornment, Typography } from '@mui/material'
import { IconCurrencyDollar } from '@tabler/icons'

// project imports
import useSetState from 'hooks/useSetState'
import { formatToDollar, parseValueByType } from 'utils/utilities'

// ==============================|| TEXT FIELD COMPONENT ||============================== //

const defaultState = {
  contentOnChange: '',
}

const TextFieldComponent = (props) => {
  const {
    callbacks: { toggleSetValue },
    clientFlags,
    flags,
    part: { detail, price_tags },
  } = props

  const [state, setState] = useSetState(defaultState)
  const { contentOnChange } = state

  useEffect(() => {
    setState({ contentOnChange: detail?.value || '' })
    if (detail?.value && detail?.value !== '') {
      toggleSetValue(parseValueByType(detail?.value, detail?.sub_type))
    }
  }, [detail?.value, detail])

  useEffect(() => {
    const debouncedUpdateValue = debounce(toggleSetValue, 500)

    debouncedUpdateValue(contentOnChange)

    return () => debouncedUpdateValue.cancel()
  }, [contentOnChange])

  return (
    <Box noValidate>
      <FormControl fullWidth>
        <OutlinedInput
          inputProps={{ min: detail?.sub_type === 'PositiveInteger' ? 0 : null }}
          onChange={(e) => setState({ contentOnChange: e.target.value })}
          placeholder={detail?.display_name}
          type={detail?.sub_type === 'String' ? 'text' : 'number'}
          value={contentOnChange || ''}
          startAdornment={
            detail?.sub_type === 'Currency' ? (
              <InputAdornment position="start">
                <IconCurrencyDollar stroke={3} size="1rem" />
              </InputAdornment>
            ) : null
          }
          endAdornment={
            <Typography>
              {!clientFlags.includes('HidePrice') && price_tags?.unit_price
                ? formatToDollar(
                    price_tags?.unit_price * (flags.includes('ValueAsQty') && !isNaN(detail?.value) ? contentOnChange || 1 : 1)
                  )
                : ''}
            </Typography>
          }
        />
      </FormControl>
    </Box>
  )
}

TextFieldComponent.propTypes = {
  callbacks: PropTypes.object,
  clientFlags: PropTypes.array,
  flags: PropTypes.array,
  part: PropTypes.object,
}

export default TextFieldComponent
