/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from '@mui/material'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import useSetState from 'hooks/useSetState'

// project imports
import NavGroup from '../components/NavGroup'
import scrollItems from 'scroll-items'

const defaultState = {
  scrollItem: 'general',
}

const ScrollList = () => {
  const [state, setState] = useSetState(defaultState)
  const { scrollItem } = state

  const location = useLocation()

  useEffect(() => {
    const currentNav = location.pathname.toString().split('/')[1]
    setState({ scrollItem: currentNav || 'general' })
  }, [location])

  const navItems = scrollItems[scrollItem]?.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        )
    }
  })

  return <>{navItems}</>
}

export default ScrollList
