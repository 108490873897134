/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useParams } from 'react-router-dom'

// material-ui
import { styled, useTheme } from '@mui/material/styles'
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material'

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs'
import Header from './Header'
import Sidebar from './Sidebar'
import navigation from 'menu-items'
import { drawerWidth, smallDrawerWidth } from 'store/constant'
import { SET_MENU } from 'store/actions'

// assets
import { IconChevronRight } from '@tabler/icons'

import useCurrentUser from 'hooks/useCurrentUser'
import useReduxAction from 'hooks/useReduxAction'

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  ...theme.typography.mainContent,
  ...(!open && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
      width: `calc(100% - ${smallDrawerWidth}px)`,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px',
      width: `calc(100% - ${smallDrawerWidth}px)`,
      padding: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
      width: `calc(100% - ${smallDrawerWidth}px)`,
      padding: '16px',
      marginRight: '10px',
    },
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
    },
  }),
}))

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme()

  const [office, setOffice] = useState('')
  const [project, setProject] = useState(null)

  const { currentUser } = useCurrentUser()
  const { id: projectId } = useParams()

  const { users, projects } = useSelector((reduxState) => reduxState.entities)

  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened)
  const dispatch = useDispatch()
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened })
  }

  useReduxAction('enums', 'loadEnums', 'MerchandiseGroup', [])
  useReduxAction('enums', 'loadEnums', 'PartType', [])
  useReduxAction('enums', 'loadEnums', 'PartSubType', [])
  useReduxAction('enums', 'loadEnums', 'ServiceType', [])
  useReduxAction('enums', 'loadEnums', 'UserRole', [])
  // unique enums
  useReduxAction('enums', 'loadEnums', 'State', [])
  useReduxAction('enums', 'loadEnums', 'PlatformType', [])

  useReduxAction('offices', 'loadOffices', { s: -1 }, [])

  useReduxAction('projects', 'loadProject', {}, [projectId], {
    dispatchAction: (action, requestOptions) => action(projectId, requestOptions),
    shouldPerformFn: (entityReducer) => {
      const { errors, loaded, loading } = entityReducer
      return !loaded && !loading && !errors.length && projectId && !currentUser?.roles?.includes('Agent')
    },
  })

  useReduxAction('users', 'loadUser', {}, [currentUser.user, project?.owner], {
    dispatchAction: (action, requestOptions) =>
      action(!currentUser?.roles?.includes('Agent') && project?.owner ? project.owner : currentUser.user, requestOptions),
    shouldPerformFn: (entityReducer) => {
      const { errors, loading } = entityReducer
      return !loading && !errors.length && (currentUser.user || project?.owner)
    },
  })

  const prevOffice = useRef(office)

  const setPrevOffice = (newValue) => {
    prevOffice.current = newValue
  }

  useEffect(() => {
    if (!currentUser?.roles?.includes('Agent') && projectId && Object.values(projects).find((project) => project?.id === projectId)) {
      setProject(Object.values(projects).find((project) => project.id === projectId))
    }
  }, [projects])

  useEffect(() => {
    if (project?.owner && projectId) {
      setOffice(users[project.owner]?.agent?.office || '')
    }
  }, [users])

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: leftDrawerOpened ? theme.transitions.create('width') : 'none',
        }}
      >
        <Toolbar>
          <Header office={office} handleLeftDrawerToggle={handleLeftDrawerToggle} handleOfficeToggle={(office) => setOffice(office)} />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

      {/* main content */}
      <Main theme={theme} open={leftDrawerOpened}>
        {/* breadcrumb */}
        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
        <Outlet context={{ office, prevOffice, setOffice, setPrevOffice }} />
      </Main>
    </Box>
  )
}

export default MainLayout
