import { lazy } from 'react'

// project imports
import MainLayout from 'layout/MainLayout'
import Loadable from 'ui-component/Loadable'
import ErrorElement from 'routes/ErrorElement'

// dashboard routing
const AssetPage = Loadable(lazy(() => import('views/Asset')))
const Bookings = Loadable(lazy(() => import('views/Bookings')))
const Dashboard = Loadable(lazy(() => import('views/Dashboard')))
const Profile = Loadable(lazy(() => import('views/Profile')))
const ProjectPage = Loadable(lazy(() => import('views/Project')))
const UploadPage = Loadable(lazy(() => import('views/Upload')))

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  errorElement: <ErrorElement />,
  children: [
    {
      path: '/',
      element: <Dashboard />,
      errorElement: <ErrorElement />,
    },
    {
      path: 'profile',
      element: <Profile />,
    },
    {
      path: 'project/:id',
      element: <ProjectPage />,
    },
    {
      path: 'project/:id/asset/:assetId',
      element: <AssetPage />,
    },
    {
      path: 'project/:id/bookings',
      element: <Bookings />,
    },
    {
      path: 'project/:id/task/:taskId/files',
      element: <UploadPage />,
    },
  ],
}

export default MainRoutes
