import React from 'react'
import PropTypes from 'prop-types'

import { Avatar } from '@mui/material'

import * as assets from 'assets/icons'

// ==============================|| CUSTOM ICON ||============================== //

const CustomIcon = (props) => {
  const { name, width = 40, height = 40, marginX, marginY } = props

  return (
    <Avatar
      variant="rounded"
      alt={name}
      src={assets[name?.replace(/\s+/g, '').toLowerCase()]}
      sx={{ background: 'transparent', marginX: marginX || 'auto', marginY: marginY || 'auto', width: width, height: height }}
    />
  )
}

CustomIcon.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  marginX: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginY: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default CustomIcon
