/* eslint-disable prettier/prettier */
import { normalize } from 'normalizr'
import { Schemas } from 'redux/schema'
import { updateEntities } from 'redux/entities'

import api from 'utils/api'

import { handleError } from '../utils'
import config from 'config'

const FETCH_REQUEST = 'contenthouse/enum/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/enum/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/enum/FETCH_FAILURE'

// Initial State
const initialState = {
  errors: [],
  loaded: false,
  loading: false,
}

// Actions
export function fetchRequest() {
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

// Action Creators
export function loadEnums(key) {
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api(`${config.utilitiesUrl}/enums/${key}`, {})
      .then(({ data }) => {
        const normalizedJson = normalize(data, [Schemas[`${key.split(/\.?(?=[A-Z])/).join('_').toUpperCase()}`]])
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, errors } = action

  switch (type) {
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors,
      }
    default:
      return state
  }
}
