import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import parse from 'autosuggest-highlight/parse'

// material-ui
import { Autocomplete, Box, Card, Grid, Stack, TextField, Typography } from '@mui/material'
import { debounce } from '@mui/material/utils'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import LocationOnIcon from '@mui/icons-material/LocationOn'

import config from 'config'

// ==============================|| GOOGLE MAP PLACES ||============================== //

function loadScript(src, position, id) {
  if (!position) {
    return
  }

  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('id', id)
  script.src = src
  position.appendChild(script)
}

const sessionToken = { current: null }
const autocompleteService = { current: null }

const GoogleMapPlaces = (props) => {
  const { onAddressChange, selectedValue } = props

  const [value, setValue] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])
  const loaded = useRef(false)

  const searchRef = useRef(null)

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${config.googleMapsApiKey}&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      )
    }

    loaded.current = true
  }

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback)
      }, 400),
    []
  )

  useEffect(() => {
    let active = true

    if (window.google) {
      if (!sessionToken.current) {
        sessionToken.current = new window.google.maps.places.AutocompleteSessionToken()
      }

      if (!autocompleteService.current) {
        autocompleteService.current = new window.google.maps.places.AutocompleteService()
      }
    }

    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch(
      { input: inputValue, componentRestrictions: config.googleComponentRestrictions, sessionToken: sessionToken.current },
      (results) => {
        if (active) {
          let newOptions = []

          if (value) {
            newOptions = [value]
          }

          if (results) {
            newOptions = [...newOptions, ...results]
          }

          setOptions(newOptions)
        }
      }
    )

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  const getPlaceDetails = (value) => {
    const map = new window.google.maps.Map(document.createElement('div'))
    const places = new window.google.maps.places.PlacesService(map)

    if (!value?.place_id) {
      return onAddressChange('')
    }

    places.getDetails({ placeId: value.place_id, sessionToken: sessionToken.current }, (results) => {
      onAddressChange(value, results)
    })
  }

  return selectedValue?.placeId !== '' ? (
    <Card variant="outlined" sx={{ p: 1 }}>
      <Stack direction="row">
        <Grid container alignItems="center">
          <Grid item sx={{ display: 'flex', width: 44 }}>
            <LocationOnIcon sx={{ color: 'text.secondary' }} />
          </Grid>
          <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              {selectedValue?.address || ''}
            </Box>
            <Typography variant="body2" color="text.secondary">
              {selectedValue?.suburb || ''} {selectedValue?.addressState || ''}
            </Typography>
          </Grid>
        </Grid>
        <Grid item sx={{ alignSelf: 'center' }}>
          <CloseOutlinedIcon
            fontSize="small"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              setValue(null)
              onAddressChange('')
              searchRef?.current?.focus()
            }}
          />
        </Grid>
      </Stack>
    </Card>
  ) : (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      fullWidth
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No locations"
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options)
        setValue(newValue)

        getPlaceDetails(newValue)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      ref={searchRef}
      renderInput={(params) => <TextField {...params} label="Search location" fullWidth />}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings || []

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        )

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part, index) => (
                  <Box key={index} component="span" sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
      sx={{ pb: 1 }}
    />
  )
}

GoogleMapPlaces.propTypes = {
  onAddressChange: PropTypes.func,
  selectedValue: PropTypes.object,
}

export default GoogleMapPlaces
