export function isValidEmail(email) {
  const trimEmail = email ? email.trim() : ''
  const emailRegex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)(?:\s)*$/i

  return emailRegex.test(trimEmail)
}

export function isValidMobile(mobile) {
  const trimMobile = mobile ? mobile.replace(/\s/g, '') : ''
  const mobileRegex = /^\+?(?:[0-9] ?){7,12}[\d]$/

  return mobileRegex.test(trimMobile)
}

export function isValidPrice(price) {
  if (price === null || typeof price === 'undefined' || price === '') return false
  return Number(price) >= 0
}

export function isValidTimeString(time) {
  const timeDurationRegex = /^([0-5][0-9]):[0-5][0-9]$/

  return timeDurationRegex.test(time)
}

export function isValidVideoFile(fileType) {
  const regex = /^video/

  return regex.test(fileType)
}

export function arrayHasValues(array) {
  return !(array === null || array.length === 0)
}

export function valuesAreEqualTypes(values) {
  const hasLimitValue = values.includes('') || values.includes(null) || values.includes(undefined)

  if (hasLimitValue) return values.every((value, index, array) => value === array[0])

  return values.every((value, index, array) => typeof value === typeof array[0])
}

export function isValidRequiredEntity(requiredFields = [], entityState = {}, dirtyFields = []) {
  // { key: '', validation: (item, entityState) => { // custom validation code }, invalidMessage: '' }
  const defaultResult = {
    isValid: true,
    errors: {},
  }

  const performValidation = (validation, validItem) => {
    if (validation && typeof validation === 'function') {
      return validation(validItem, entityState)
    }

    return !!validItem
  }

  const result = requiredFields.reduce((accumulator, item) => {
    const { isValid, errors } = accumulator
    const { key, validation, invalidMessage } = item

    const isObjectField = key.includes('.')
    function getObjectItem() {
      const [rootObjectName, objectKey] = key.split('.')
      const rootObject = entityState[rootObjectName]
      return rootObject[objectKey]
    }

    const validateItem = isObjectField ? getObjectItem() : entityState[key]
    const isItemDirty = dirtyFields.includes(key)
    const isItemValid = performValidation(validation, validateItem)

    const shouldShowError = isItemDirty && !isItemValid
    const errorMessage = (Array.isArray(validateItem) ? validateItem.length : validateItem) ? invalidMessage : 'Required'
    const updatedErrors = shouldShowError ? { ...errors, [key]: errorMessage } : errors

    return {
      errors: updatedErrors,
      isValid: isValid && isItemValid,
    }
  }, defaultResult)

  return result
}
