import api from 'utils/api'

import { handleError } from '../utils'
import config from 'config'

const FETCH_REQUEST = 'contenthouse/currentUser/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/currentUser/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/currentUser/FETCH_FAILURE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest() {
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(payload = {}) {
  return {
    type: FETCH_SUCCESS,
    payload,
  }
}

export function fetchFailure(errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function loadCurrentUser(options) {
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api(config.meUrl, options)
      .then(({ data }) => {
        const promise1 = api(`/users/${data.user}`, options)
          .then(({ data: userData }) => {
            const currentUser = { ...data, details: userData }
            dispatch(fetchSuccess(currentUser))

            return { success: true, data: currentUser }
          })
          .catch((data) => {
            const errors = handleError(data)
            dispatch(fetchFailure(errors))

            return { success: false, errors }
          })

        return promise1
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export default function reducer(state = initialState, action = {}) {
  const { type, payload, errors } = action

  switch (type) {
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        errors: [],
        loaded: true,
        loading: false,
        result: payload,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        errors,
        loaded: false,
        loading: false,
      }
    default:
      return state
  }
}
