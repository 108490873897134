import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import { Box, InputLabel, Grid, Typography } from '@mui/material'

// project imports
import AddressComponent from './components/AddressComponent'
import BooleanComponent from './components/BooleanComponent'
import ChecklistComponent from './components/ChecklistComponent'
import DateTimeComponent from './components/DateTimeComponent'
import RadioComponent from './components/RadioComponent'
// import SelectComponent from './components/SelectComponent'
import StaticComponent from './components/StaticComponent'
import TextFieldComponent from './components/TextFieldComponent'
import TimeRangeComponent from './components/TimeRangeComponent'

// ==============================|| PART TYPES ||============================== //

const PartTypes = (props) => {
  const {
    callbacks,
    part,
    part: { detail, hideLabel = false },
  } = props

  const componentMapping = (type, subType) => {
    const componentMap = {
      MultiSelect: {
        Default: ChecklistComponent,
      },
      SingleSelect: {
        Default: RadioComponent,
      },
      Value: {
        Address: AddressComponent,
        Boolean: BooleanComponent,
        DateTime: DateTimeComponent,
        TimeRange: TimeRangeComponent,
        Default: TextFieldComponent,
      },
    }

    return componentMap[type]?.[subType] || componentMap[type]?.Default || <Typography>Component Unavailable</Typography>
  }

  const Component = componentMapping(detail?.type, detail?.sub_type)

  const flags = detail?.flags || []
  const clientFlags = detail?.constant?.client_ui?.flags || []

  if (clientFlags.includes('HidePart')) return

  return (
    <Box component="form" noValidate>
      {flags.includes('Static') ? (
        <StaticComponent part={part} />
      ) : (
        <Grid container alignItems="start" spacing={1}>
          {!hideLabel && (
            <Grid item md={4} xs={12}>
              <InputLabel
                sx={{
                  pt:
                    ['MultiSelect', 'SingleSelect'].includes(detail?.type) || (detail?.type === 'Value' && detail?.sub_type === 'Boolean')
                      ? '11px'
                      : 2,
                  whiteSpace: 'normal',
                }}
              >
                {detail?.display_name?.trim() || ''}
                {flags?.includes('RequiredByPlacingOrder') ? ' *' : null}
              </InputLabel>
            </Grid>
          )}
          <Grid item md={hideLabel ? 12 : 8} xs={12}>
            {Component && <Component key={detail?.number} part={part} callbacks={callbacks} flags={flags} clientFlags={clientFlags} />}
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

PartTypes.propTypes = {
  callbacks: PropTypes.object,
  part: PropTypes.object,
}

export default PartTypes
