/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

// material-ui
import { Box, OutlinedInput, Stack, Typography } from '@mui/material'

// project imports
import useSetState from 'hooks/useSetState'
import { parseValueByType } from 'utils/utilities'

// ==============================|| TIME RANGE COMPONENT ||============================== //

const defaultState = {
  startTime: '',
  endTime: '',
  error: '',
}

const TimeRangeComponent = (props) => {
  const {
    callbacks: { toggleSetValue },
    part: { detail },
  } = props

  const [state, setState] = useSetState(defaultState)
  const { startTime, endTime, error } = state

  useEffect(() => {
    setState({ startTime: detail?.value?.start || '', endTime: detail?.value?.end || '' })
  }, [])

  const updateTimeRange = (value, type) => {
    const timeRange = {
      start: detail?.value?.start,
      end: detail?.value?.end,
    }

    timeRange[type] = parseValueByType(value, detail?.sub_type)

    if (type === 'start') setState({ startTime: value })
    else setState({ endTime: value })

    const errorMessage = !Object.values(timeRange).every((x) => [undefined, ''].includes(x))
      ? Object.values(timeRange).some((x) => [undefined, ''].includes(x))
        ? 'Time Range is not valid'
        : ''
      : ''

    setState({ error: errorMessage })

    toggleSetValue(timeRange, errorMessage)
  }

  return (
    <Box noValidate>
      <Stack spacing={1}>
        <Stack direction="row" spacing={1}>
          <OutlinedInput onChange={(e) => updateTimeRange(e.target.value, 'start')} value={startTime} type="time" fullWidth />
          <OutlinedInput onChange={(e) => updateTimeRange(e.target.value, 'end')} value={endTime} type="time" fullWidth />
        </Stack>
        <Typography variant="subtitle2" color="error">
          {error}
        </Typography>
      </Stack>
    </Box>
  )
}

TimeRangeComponent.propTypes = {
  callbacks: PropTypes.object,
  part: PropTypes.object,
}

export default TimeRangeComponent
