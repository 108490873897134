import { useSelector } from 'react-redux'

import cloneDeep from 'lodash/cloneDeep'

import useForm from 'hooks/useForm'
import useLatestEntity from 'hooks/useLatestEntity'
import useThunkDispatch from 'hooks/useThunkDispatch'
import defaultState, { requiredFields } from 'models/user'

import * as userActions from 'redux/modules/user'

const updateUser = (userParams, dispatch, requestOptions) => {
  const { updateUser: updateFn } = userActions
  return dispatch(updateFn(userParams, requestOptions))
}

export function useUserForm(user = {}) {
  const userForm = useForm(defaultState, { entity: { ...defaultState, ...user }, requiredFields, validateOn: 'change' }, [user.id])
  const initialValue = Object.keys(user).length === 0 ? defaultState : cloneDeep(userForm.entityState)

  return {
    ...userForm,
    initialValue,
  }
}

const useUser = (initUser = {}) => {
  const { entity: user } = useLatestEntity(initUser, 'users')

  const dispatch = useThunkDispatch()

  const { creating, deleting, loading, updating } = useSelector((reduxState) => reduxState.users)

  return {
    user,
    callbacks: {
      updateUser: (params, requestOptions) => updateUser(params, dispatch, requestOptions),
    },
    creating,
    deleting,
    loading,
    updating,
  }
}

export default useUser
