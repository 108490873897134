import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// material-ui
import { Collapse, Divider, ListItemButton, ListItemText, Typography } from '@mui/material'

// project imports
import { IconChevronDown, IconChevronUp } from '@tabler/icons'

// ==============================|| COLLAPSIBLE LIST ITEM ||============================== //

const CollapsibleListItem = (props) => {
  const {
    children,
    collapsibleSX,
    forceOpen,
    marginBottom = 1,
    paddingX = 1,
    paddingY = 1,
    primaryNode,
    primaryText,
    reverse,
    showDivider,
  } = props

  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(forceOpen)
  }, [forceOpen])

  return (
    <>
      <ListItemButton
        sx={{
          paddingX: paddingX,
          paddingY: paddingY,
          borderRadius: 3,
          mb: marginBottom,
          flexDirection: reverse ? 'row-reverse' : 'row',
          ...collapsibleSX,
        }}
        onClick={() => setOpen(!open)}
      >
        {open ? (
          <IconChevronUp stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto', marginRight: 5 }} />
        ) : (
          <IconChevronDown stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto', marginRight: 5 }} />
        )}
        <ListItemText
          primary={
            primaryNode ? (
              primaryNode
            ) : (
              <Typography variant="h4" sx={{ fontWeight: 500 }}>
                {primaryText}
              </Typography>
            )
          }
        />
      </ListItemButton>
      <Collapse in={open} sx={{ mb: open ? 1 : 0 }}>
        {children}
        {showDivider && <Divider sx={{ mt: 2 }} />}
      </Collapse>
    </>
  )
}

CollapsibleListItem.propTypes = {
  children: PropTypes.node,
  collapsibleSX: PropTypes.object,
  forceOpen: PropTypes.bool,
  marginBottom: PropTypes.number,
  paddingX: PropTypes.number,
  paddingY: PropTypes.number,
  primaryNode: PropTypes.node,
  primaryText: PropTypes.string,
  reverse: PropTypes.bool,
  showDivider: PropTypes.bool,
}

export default CollapsibleListItem
