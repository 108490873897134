/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

// material-ui
import { Box, Checkbox, FormControl, FormControlLabel, Stack, Typography } from '@mui/material'

// project imports
import useSetState from 'hooks/useSetState'
import { formatToDollar, parseValueByType } from 'utils/utilities'

// ==============================|| BOOLEAN COMPONENT ||============================== //

const defaultState = {
  contentOnChange: '',
}

const BooleanComponent = (props) => {
  const {
    callbacks: { toggleSetValue },
    clientFlags,
    part: { detail, price_tags },
  } = props

  const [state, setState] = useSetState(defaultState)
  const { contentOnChange } = state

  useEffect(() => {
    setState({ contentOnChange: detail?.value || '' })
    if (detail?.value && detail?.value !== '') {
      toggleSetValue(parseValueByType(detail?.value, detail?.sub_type))
    }
  }, [detail?.value, detail])

  const updateCheckbox = (value) => {
    setState({ contentOnChange: value })
    toggleSetValue(value)
  }

  return (
    <Box noValidate>
      <FormControl fullWidth>
        <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between', width: '100%' }} spacing={1}>
          <FormControlLabel
            control={<Checkbox checked={Boolean(contentOnChange || 0)} onChange={(e) => updateCheckbox(e.target.checked)} />}
            label=""
          />
          {!clientFlags.includes('HidePrice') && price_tags?.unit_price && (
            <Typography textAlign="end" sx={{ paddingRight: '13px' }}>
              {formatToDollar(price_tags?.unit_price)}
            </Typography>
          )}
        </Stack>
      </FormControl>
    </Box>
  )
}

BooleanComponent.propTypes = {
  callbacks: PropTypes.object,
  clientFlags: PropTypes.array,
  part: PropTypes.object,
}

export default BooleanComponent
