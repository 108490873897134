import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import { Box, Drawer, Grid, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar'

// ==============================|| HEADER ||============================== //

export const Header = (props) => {
  const theme = useTheme()

  const { heading, headingNode, secondHeading, callbacks } = props
  const { closeModal } = callbacks || {}

  return (
    <Grid item xs={12} px={2} py={4} borderBottom={1} borderColor={theme.palette.primary['light']}>
      <Grid container alignContent="center" justifyContent="space-between">
        <Grid item>
          <Stack>
            <Typography variant="h3">{heading}</Typography>
            <Typography variant="h3">{secondHeading}</Typography>
            {headingNode}
          </Stack>
        </Grid>
        <Grid item>
          <CloseOutlinedIcon sx={{ cursor: 'pointer' }} onClick={closeModal} />
        </Grid>
      </Grid>
    </Grid>
  )
}

Header.propTypes = {
  heading: PropTypes.string,
  headingNode: PropTypes.node,
  secondHeading: PropTypes.string,
  callbacks: PropTypes.object,
}

// ==============================|| CONTENT ||============================== //

export const Content = ({ children }) => {
  return <PerfectScrollbar component="div">{children}</PerfectScrollbar>
}

Content.propTypes = {
  children: PropTypes.node,
}

// ==============================|| FOOTER ||============================== //

export const Footer = ({ children }) => {
  const theme = useTheme()

  return (
    <Box p={2} borderTop={1} borderColor={theme.palette.primary['light']}>
      {children}
    </Box>
  )
}

Footer.propTypes = {
  children: PropTypes.node,
}

// ==============================|| CONTROLLER ||============================== //

export const RenderController = (props) => {
  const { children, showModal } = props

  if (!showModal) {
    return null
  }

  return children
}

RenderController.propTypes = {
  children: PropTypes.node,
  showModal: PropTypes.bool,
}

// ==============================|| MAIN CONTAINER ||============================== //

const ModalContainer = (props) => {
  const { callbacks, children, showModal, size = 'small' } = props

  const { closeModal } = callbacks || {}

  const getMaxWidth = () => {
    switch (size) {
      case 'large':
        return '750px'
      case 'fullscreen':
        return '100%'
      default:
        return '375px'
    }
  }

  return (
    <Drawer
      anchor="right"
      onClose={closeModal}
      open={showModal}
      PaperProps={{
        sx: { width: '100%', maxWidth: getMaxWidth(size) },
      }}
    >
      {children}
    </Drawer>
  )
}

ModalContainer.Header = Header
ModalContainer.Content = Content
ModalContainer.Footer = Footer
ModalContainer.RenderController = RenderController

ModalContainer.propTypes = {
  callbacks: PropTypes.object,
  children: PropTypes.node,
  showModal: PropTypes.bool,
  size: PropTypes.string,
}

export default ModalContainer
