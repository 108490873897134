/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { useTheme } from '@mui/material/styles'
// material-ui
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import ArchiveOutlined from '@mui/icons-material/ArchiveOutlined'

// project imports
import { digObject } from 'utils/utilities'
import config from 'config'
import CustomIcon from 'ui-component/CustomIcon'
import ModalContainer from 'ui-component/containers/ModalContainer'
import ModalContext from 'contexts/modalContext'
import PartTypes from 'ui-component/PartTypes'
import useCurrentUser from 'hooks/useCurrentUser'
import useProject, { useProjectForm } from 'hooks/useProject'
import useReduxAction from 'hooks/useReduxAction'
import useSetState from 'hooks/useSetState'

//state
const defaultState = {
  disableFields: false,
  errors: [],
  openArchive: false,
  ownerUser: null,
  projectUpdated: false,
  skipChecked: false,
}

const ProjectModal = (props) => {
  const navigate = useNavigate()

  const [state, setState] = useSetState(defaultState)
  const { disableFields, errors, openArchive, ownerUser, projectUpdated, skipChecked } = state

  const theme = useTheme()

  const { callbacks, modalKey, showModal } = props
  const { closeModal } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, `${modalKey}`, {})

  const { project, office } = modalPayload

  const entityReducer = useSelector((reduxState) => reduxState.projectTemplates)
  const { result: projectTemplate, loading: templateLoading } = entityReducer

  const { users } = useSelector((reduxState) => reduxState.entities)

  const projectFormPayload = useProjectForm(project)
  const {
    entityState,
    entityState: { display_name, parts, owner, collaborators },
    saveEnabled,
    setEntityState,
  } = projectFormPayload

  const {
    callbacks: { createProject: createFn, updateProject: updateFn, archiveProject: archiveFn },
    creating,
    updating,
    deleting,
  } = useProject()

  const { currentUser } = useCurrentUser()

  useReduxAction('projectTemplates', 'loadProjectTemplate', {}, [], {
    dispatchAction: (action, requestOptions) => action({ parts: config.defaultProjectParts }, requestOptions),
    shouldPerformFn: (entityReducer) => {
      const { errors, loading } = entityReducer
      return !loading && !errors.length && !project?.id
    },
  })

  useReduxAction('users', 'loadUser', {}, [owner], {
    dispatchAction: (action, requestOptions) => action(owner, requestOptions),
    shouldPerformFn: (entityReducer) => {
      const { errors, loading } = entityReducer
      return !loading && !errors.length && owner
    },
  })

  const userOptions = {
    size: -1,
    f: `agent.office|=|${ownerUser?.agent?.office || office}`,
  }

  useReduxAction('users', 'loadUsers', userOptions, [ownerUser, office], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loading } = entityReducer
      return !loading && !errors.length && (ownerUser || office)
    },
  })

  const save = (id) => {
    if (id) {
      updateFn({ ...entityState, _etag: project._etag }).then(({ success, errors, result }) => {
        if (!success && errors) {
          toast.warn(errors[0])
          return
        }
        closeModal()
      })
    } else {
      createFn(entityState).then(({ success, errors, result }) => {
        if (!success && errors) {
          toast.warn(errors[0])
          return
        }
        closeModal()
        navigate(`/project/${result.id}`, {
          state: {
            category: project ? project?.category?.icon : '',
          },
        })
      })
    }
  }

  const archive = () => {
    archiveFn(project).then(({ success, errors }) => {
      if (!success && errors) {
        toast.warn(errors[0])
        return
      }
      closeModal()
      navigate('/')
    })
  }

  const setArchive = (val) => {
    setState({ openArchive: val })
  }

  const setValue = (part, value, error) => {
    const compositeId = part.number
    const existingError = errors.some((x) => x.id === part.number)
    if (error && !existingError) {
      setState({ errors: [{ id: compositeId, error }, ...errors] })
    } else if (!error && existingError) {
      setState({ errors: errors.filter((x) => x.id != compositeId) })
    }

    if (value || value === false) {
      part.value = value

      if (part.name === 'Service Address' && !projectUpdated && value.address) {
        entityState.display_name = value.address + (value.suburb ? ', ' + value.suburb : '')
      }
    } else if (Object.hasOwn(part, 'value')) {
      delete part.value
    }

    setEntityState(entityState)
  }

  useEffect(() => {
    if (project === undefined && skipChecked) {
      setState({ disableFields: true })
    } else if ((!skipChecked && project === undefined) || project) {
      setState({ disableFields: false })
      setState({ skipChecked: false })
    }
  }, [project, skipChecked])

  useEffect(() => {
    setEntityState({
      parts: project?.id ? project?.parts || [] : projectTemplate?.parts || [],
      owner: project?.id ? project?.owner : office ? '' : currentUser.user,
    })
  }, [projectTemplate, project?.id])

  useEffect(() => {
    if (users[owner] && users[owner].id !== ownerUser?.id) {
      setState({ ownerUser: users[owner] })
    }
  }, [users])

  useEffect(() => {
    if (project?.id && display_name) {
      setState({ projectUpdated: true })
    }
  }, [project, display_name])

  return (
    <ModalContainer callbacks={callbacks} modalKey={modalKey} showModal={showModal} size={project?.category ? '' : 'large'}>
      <ModalContainer.Header
        callbacks={callbacks}
        heading={project?.id ? 'Edit Project' : project?.category ? 'Quick Order' : 'New Project'}
        headingNode={
          !project?.id && project?.category ? (
            <Chip
              icon={<CustomIcon name={project?.category?.icon} width={23} height={23} marginY={2} marginX={1} />}
              label={project?.category?.name}
              sx={{ mt: 1, '& .MuiChip-label': { pl: 0 } }}
            />
          ) : null
        }
      />
      <ModalContainer.Content>
        <Stack direction="column" p={2} spacing={2}>
          {parts
            ?.filter((x) => x.name === 'Service Address')
            ?.map((part) => (
              <PartTypes
                key={part.number}
                callbacks={{ toggleSetValue: (value, error) => setValue(part, value, error) }}
                part={{ detail: part, hideLabel: true }}
              />
            ))}
          <TextField
            id="outlined-basic"
            label="Project Name"
            required
            value={display_name}
            variant="outlined"
            fullWidth
            onChange={(e) => {
              setState({ projectUpdated: true })
              setEntityState({ display_name: e.target.value })
            }}
          />
          {(!currentUser?.roles?.includes('Agent') && office) || (!currentUser?.roles?.includes('Agent') && project?.owner) ? (
            <Autocomplete
              name="Owner"
              fullWidth
              options={Object.values(users)
                .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1))
                .filter((x) => (office ? x.agent?.office === office : x.agent?.office === ownerUser?.agent?.office))}
              getOptionLabel={(option) => (option.name ? option.name : '')}
              onChange={(_, value) => {
                setEntityState({ owner: value?.id || '' })
              }}
              renderInput={(params) => <TextField {...params} name="owner" label="Owner" helperText={errors['owner']} required />}
              value={Object.values(users).find((x) => x.id === owner) || null}
            />
          ) : (
            <TextField id="outlined-basic" label="Owner" disabled value={ownerUser?.name || 'No Owner'} variant="outlined" fullWidth />
          )}
          <Autocomplete
            name="Collaborators"
            multiple
            autoComplete
            fullWidth
            options={Object.values(users)
              .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1))
              .filter((x) => x.id !== owner)
              .filter((x) => (office ? x.agent?.office === office : x.agent?.office === ownerUser?.agent?.office))}
            getOptionLabel={(option) => (option.name ? option.name : '')}
            includeInputInList
            autoSelect
            onChange={(_, value) => {
              const userIds = value.map((x) => x.id)
              setEntityState({ collaborators: userIds })
            }}
            renderInput={(params) => (
              <TextField {...params} name="collaborators" label="Collaborators" helperText={errors['collaborators']} />
            )}
            value={
              Object.values(users)
                .filter((x) => collaborators.includes(x.id))
                .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1)) || null
            }
          />
          {project === undefined && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={skipChecked}
                  onChange={(e) => setState({ skipChecked: e.target.checked })}
                  size="small"
                  style={{ paddingLeft: 0 }}
                />
              }
              label="Skip out details for later"
              labelPlacement="end"
              value="end"
            />
          )}
          {!(!project?.id && project?.category) && (
            <Stack
              direction="column"
              spacing={2}
              p={1}
              sx={
                disableFields
                  ? {
                      background: theme.palette.background.paper,
                      opacity: '0.3',
                      pointerEvents: 'none',
                    }
                  : {}
              }
            >
              {templateLoading ? (
                <Skeleton variant="rectangular" animation="wave" height="50vh" />
              ) : (
                parts
                  ?.filter((x) => x.name !== 'Service Address')
                  ?.map((part) => (
                    <PartTypes
                      key={part.number}
                      callbacks={{ toggleSetValue: (value, error) => setValue(part, value, error) }}
                      part={{ detail: part }}
                    />
                  ))
              )}
            </Stack>
          )}
          {entityState?.id && (
            <Stack direction="column" spacing={2}>
              <Typography variant="h5">Other Options</Typography>
              <LoadingButton
                loading={updating}
                disabled={!entityState?.id}
                variant="contained"
                disableElevation
                fullWidth
                onClick={() => setArchive(true)}
              >
                Archive
              </LoadingButton>
            </Stack>
          )}
        </Stack>
        <Dialog
          open={openArchive}
          onClose={() => setArchive(false)}
          sx={{
            '& .MuiDialogTitle-root': { fontSize: '1.25rem!important' },
          }}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle sx={{ borderBottom: 1, marginBottom: 1, borderColor: theme.palette.primary.light }}>
            <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
              <Typography variant="h4" sx={{ fontWeight: 500 }}>
                Confirm Archive
              </Typography>
              <CloseOutlinedIcon fontSize="small" sx={{ cursor: 'pointer' }} onClick={() => setArchive(false)} />
            </Stack>
          </DialogTitle>
          <DialogContent sx={{ textAlign: 'center' }}>
            <ArchiveOutlined sx={{ fontSize: 80 }} />
            <Typography variant="h4" sx={{ py: 1 }}>
              Are you sure?
            </Typography>
            <DialogContentText>You are about to archive this project.</DialogContentText>
            <DialogContentText>Click "Confirm" to proceed.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setArchive(false)}>Cancel</Button>
            <LoadingButton loading={deleting} variant="contained" disableElevation onClick={() => archive()}>
              Confirm
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </ModalContainer.Content>
      <ModalContainer.Footer>
        <LoadingButton
          disableElevation
          disabled={
            !saveEnabled ||
            templateLoading ||
            !!errors.length ||
            !(parts?.find((x) => x.name === 'Service Address')?.value?.address || null)
          }
          loading={creating || updating}
          fullWidth
          onClick={() => save(project?.id)}
          variant="contained"
        >
          {project?.id ? 'Save' : 'Create'}
        </LoadingButton>
        <LoadingButton onClick={closeModal} variant="outlined" fullWidth style={{ marginTop: 6 }}>
          Cancel
        </LoadingButton>
      </ModalContainer.Footer>
    </ModalContainer>
  )
}

ProjectModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

ProjectModal.defaultProps = {
  modalKey: 'ProjectModal',
}

const LazyLoadedModal = (props) => (
  <ModalContainer.RenderController {...props}>
    <ProjectModal {...props} />
  </ModalContainer.RenderController>
)

export default LazyLoadedModal
