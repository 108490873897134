import { normalize } from 'normalizr'
import { Schemas } from 'redux/schema'
import { updateEntities } from 'redux/entities'

import api from 'utils/api'

import { handleError } from '../utils'

const FETCH_REQUEST = 'contenthouse/user/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/user/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/user/FETCH_FAILURE'

const UPDATE_REQUEST = 'contenthouse/user/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'contenthouse/user/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'contenthouse/user/UPDATE_FAILURE'

// Initial State
const initialState = {
  errors: [],
  loaded: false,
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest() {
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(payload = {}) {
  return {
    type: FETCH_SUCCESS,
    payload,
  }
}

export function fetchFailure(errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function updateRequest() {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess() {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []) {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadUsers(options) {
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api('/users', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.USER_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess(data))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function loadUser(id, options) {
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api(`/users/${id}`, options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.USER)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess(data))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function updateUser(user, options = {}) {
  const config = {
    method: 'PUT',
    data: user,
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/users/${user.id}`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.USER)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, result: data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export default function reducer(state = initialState, action = {}) {
  const { type, payload, errors } = action

  switch (type) {
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        errors: [],
        loaded: true,
        loading: false,
        result: payload,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        errors,
        loaded: false,
        loading: false,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors,
      }
    default:
      return state
  }
}
