import { forwardRef } from 'react'
import PropTypes from 'prop-types'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Avatar, ButtonBase } from '@mui/material'

// ==============================|| AVATAR BUTTON ||============================== //

const AvatarButton = forwardRef((props, _) => {
  const { background, callbacks, children, color, disabled, downloadUrl, transparent, size } = props

  const { toggleClick } = callbacks || {}

  const theme = useTheme()

  return (
    <ButtonBase disabled={disabled} sx={{ borderRadius: '12px', overflow: 'hidden' }}>
      <Avatar
        variant="rounded"
        sx={{
          ...theme.typography.commonAvatar,
          ...theme.typography.mediumAvatar,
          transition: 'all .2s ease-in-out',
          background: transparent ? 'transparent' : background || theme.palette.primary.light,
          color: color || theme.palette.primary.dark,
          ...(size && { width: size, height: size }),
          '&:hover': {
            background: transparent ? theme.palette.grey[50] : color || theme.palette.primary.dark,
            color: background || theme.palette.primary.light,
          },
        }}
        onClick={(e) => (downloadUrl || downloadUrl === '' ? null : toggleClick(e))}
        color="inherit"
        as={downloadUrl ? 'a' : 'div'}
        href={downloadUrl}
        target="_blank"
      >
        {children}
      </Avatar>
    </ButtonBase>
  )
})
AvatarButton.propTypes = {
  background: PropTypes.string,
  callbacks: PropTypes.object,
  children: PropTypes.node,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  transparent: PropTypes.bool,
  downloadUrl: PropTypes.string,
  size: PropTypes.number,
}

export default AvatarButton
