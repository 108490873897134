/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from 'react'

import useSetState from 'hooks/useSetState'

import { digObject, deepSetObject } from 'utils/utilities'
import { isValidRequiredEntity } from 'utils/validations'

const isObject = (value) => typeof value === 'object' && !Array.isArray(value) && value !== null

const setupState = (entity, defaultModelState, setEntityState) => {
  const state = Object.keys(defaultModelState).reduce((acc, key) => {
    const defaultValue = defaultModelState[key]
    const newValue = entity[key]

    const defaultIsObject = isObject(defaultValue)
    const newIsObject = isObject(newValue)

    const updatedValue =
      defaultIsObject && newIsObject ? { ...JSON.parse(JSON.stringify(defaultValue)), ...JSON.parse(JSON.stringify(newValue)) } : newValue

    acc[key] = updatedValue === undefined || updatedValue === null ? defaultValue : updatedValue

    return acc
  }, {})

  setEntityState({ ...state })
}

const setupDirtyFields = (entity, defaultModelState, setFormState) => {
  const dirtyFields = Object.keys(entity).filter((key) => entity[key] !== defaultModelState[key])

  setFormState({ dirtyFields })
}

const parseValueByType = (e) => {
  const { value, type, dataset } = e.target
  const valueType = digObject(dataset, 'valueType')

  if (value && (type === 'number' || valueType === 'number')) return Number(value)
  if (valueType === 'boolean') return value === 'true'

  return value
}

const handleOnBlur = (e, formStateHook, entityStateHook, onBlurFn) => {
  const [entityState] = entityStateHook
  const [formState, setFormState] = formStateHook
  const { name } = e.target

  if (name) {
    const { blurFields } = formState
    setFormState({ blurFields: [...blurFields, name] })

    // Use a custom onBlurFn if provided
    if (onBlurFn) onBlurFn(entityState)
  }
}

const updateDirtyFields = (fields = [], formStateHook) => {
  const [formState, setFormState] = formStateHook
  const { dirtyFields } = formState
  const updatedDirtyFields = [...dirtyFields, ...fields]

  setFormState({ dirtyFields: updatedDirtyFields })
}

const handleOnChange = (e, entityStateHook, formStateHook, options) => {
  const { validateOn } = options
  const [entityState, setEntityState] = entityStateHook
  const { name, dataset } = e.target
  const validateFieldOn = digObject(dataset, 'validateFieldOn')

  if (!name) return

  const updatedValue = parseValueByType(e)
  const isObjectField = name.includes('.')

  if (isObjectField) {
    const [rootObjectName, ...restKeys] = name.split('.')
    const rootObject = entityState[rootObjectName]
    setEntityState({ [rootObjectName]: deepSetObject(rootObject, [...restKeys].join('.'), updatedValue) })
    return
  }

  setEntityState({ [name]: updatedValue })

  if ((validateOn === 'change' && validateFieldOn !== 'blur') || validateFieldOn === 'change') {
    updateDirtyFields([`${name}`], formStateHook)
  }
}

const updateBlurFields = (fields = [], formStateHook) => {
  const [formState, setFormState] = formStateHook
  const { blurFields } = formState
  const updatedBlurFields = [...blurFields, ...fields]

  setFormState({ blurFields: updatedBlurFields })
}

const handleSetEntityState = (fields, setEntityState, formStateHook, options) => {
  const { validateOn } = options || {}

  setEntityState(fields)

  if (validateOn === 'change') {
    return updateDirtyFields(Object.keys(fields), formStateHook)
  }

  updateBlurFields(Object.keys(fields), formStateHook)
}

const handleResetDefaultState = (defaultState, defaultFormState, setEntityState, setFormState) => {
  setEntityState({ ...defaultState })
  setFormState({ ...defaultFormState })
}

const defaultFormState = {
  blurFields: [],
  dirtyFields: [],
}

function useForm(
  defaultState,
  options = {
    entity: {},
    requiredFields: [],
    validateOn: 'blur',
  },
  deps = []
) {
  const { entity, onBlurFn } = options || {}
  const requiredFields = digObject(options, 'requiredFields', [])

  const entityStateHook = useSetState(defaultState)
  const [entityState, setEntityState] = entityStateHook

  const formStateHook = useSetState(defaultFormState)
  const [formState, setFormState] = formStateHook
  const { blurFields, dirtyFields } = formState

  const { isValid: saveEnabled, errors } = useMemo(() => {
    const fieldsToValidate = [...new Set([...blurFields, ...dirtyFields])]

    return isValidRequiredEntity(requiredFields, entityState, fieldsToValidate)
  }, [blurFields.length, dirtyFields.length])

  useEffect(() => {
    if (entity) {
      setupState(entity, defaultState, setEntityState)
      setupDirtyFields(entity, defaultState, setFormState)
    }
  }, [...deps])

  return {
    blurFields,
    dirtyFields,
    entityState,
    errors,
    onBlurEntityState: (fieldName) => updateBlurFields([fieldName], formStateHook),
    resetDefaultState: () => handleResetDefaultState(defaultState, defaultFormState, setEntityState, setFormState),
    saveEnabled,
    setEntityState: (fields) => handleSetEntityState(fields, setEntityState, formStateHook, options),
    handlers: {
      onBlur: (e) => handleOnBlur(e, formStateHook, entityStateHook, onBlurFn),
      onChange: (e) => handleOnChange(e, entityStateHook, formStateHook, options),
    },
  }
}

export default useForm
