import React from 'react'

import useSetState from 'hooks/useSetState'

/*
 * Use this hook when you want to keep track of how many
 * modals have been opened and which one is the base (first opened)
 *
 * Wrap the page in the ModalContext.Provider to provide context to the SidebarModal
 *
 */

export const ModalContext = React.createContext({})

const registerModal = (modalKey, showModal, state, setState) => {
  const { openModalKeys } = state

  const updatedOpenKeys = [...openModalKeys]

  // Add the key to the open modals is visible, otherwise remove
  const index = openModalKeys.findIndex((key) => key === modalKey)
  if (showModal && index === -1) {
    updatedOpenKeys.push(modalKey)
  }

  // Remove the modal key when closed
  if (!showModal && index !== -1) {
    updatedOpenKeys.splice(index, 1)
  }

  setState({
    openModalKeys: updatedOpenKeys,
  })
}

const setModalData = (modalKey, data, state, setState) => {
  const { modalData } = state

  const updatedData = {
    ...modalData,
    [modalKey]: data,
  }

  setState({ modalData: updatedData })
}

const defaultState = {
  openModalKeys: [],
  modalData: {},
}

function useModals() {
  const [state, setState] = useSetState(defaultState)
  const { openModalKeys } = state

  const baseModalKey = openModalKeys[0] || null
  const topModalKey = openModalKeys[openModalKeys.length - 1]

  return {
    baseModalKey,
    callbacks: {
      registerModal: (modalKey, showModal) => registerModal(modalKey, showModal, state, setState),
      setModalData: (modalKey, data) => setModalData(modalKey, data, state, setState),
      setState,
    },
    openModalKeys,
    topModalKey,
    ...state,
  }
}

export default useModals
