import { isValidEmail } from 'utils/validations'

const state = {
  id: null,
  agent: null,
  email: '',
  employment: '',
  flags: [],
  jobtrack_username: '',
  management: null,
  name: '',
  roles: [],
  username: '',
}

export const requiredFields = [{ key: 'name' }, { key: 'email', validation: isValidEmail, invalidMessage: 'Invalid email format' }]

export default state
