import PropTypes from 'prop-types'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, Stack } from '@mui/material'
import { IconMenu2 } from '@tabler/icons'

// project imports
import AvatarButton from 'ui-component/AvatarButton'

import LogoSection from '../LogoSection'
import ActionSection from './ActionSection'
import SearchSection from './SearchSection'
import ProfileSection from './ProfileSection'
import NotificationSection from './NotificationSection'

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle, handleOfficeToggle, office }) => {
  const theme = useTheme()

  return (
    <>
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto',
          },
        }}
      >
        <AvatarButton
          background={theme.palette.secondary.light}
          callbacks={{ toggleClick: () => handleLeftDrawerToggle() }}
          color={theme.palette.secondary.dark}
        >
          <IconMenu2 stroke={1.5} size="1.3rem" />
        </AvatarButton>
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1, pl: 2.5 }}>
          <LogoSection />
        </Box>
      </Box>

      <SearchSection />
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
        <ActionSection office={office} />
        <ProfileSection office={office} toggleOffice={handleOfficeToggle} />
      </Stack>
    </>
  )
}

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
  handleOfficeToggle: PropTypes.func,
  office: PropTypes.string,
}

export default Header
