import { useContext } from 'react'
import { useSelector } from 'react-redux'

import cloneDeep from 'lodash/cloneDeep'

import PageContext from 'contexts/pageContext'
import defaultState, { requiredFields } from 'models/project'
import useForm from 'hooks/useForm'
import useLatestEntity from 'hooks/useLatestEntity'
import useThunkDispatch from 'hooks/useThunkDispatch'

import * as projectActions from 'redux/modules/project'

const createProject = (projectParams, dispatch, requestOptions) => {
  const { createProject: createFn } = projectActions
  return dispatch(createFn(projectParams, requestOptions))
}

const updateProject = (projectParams, dispatch, requestOptions) => {
  const { updateProject: updateFn } = projectActions
  return dispatch(updateFn(projectParams, requestOptions))
}

const archiveProject = (projectParams, dispatch, requestOptions) => {
  const { archiveProject: archiveFn } = projectActions
  return dispatch(archiveFn(projectParams, requestOptions))
}

const createOrEditProject = (project, dispatch, showProjectModal) =>
  new Promise((resolve, reject) => {
    if (showProjectModal) {
      const payload = {
        project,
      }

      showProjectModal(payload)

      return resolve({ success: true, result: payload })
    }

    return reject(new Error('showProjectModal not defined in PageContext callbacks'))
  })

export function useProjectForm(project = {}) {
  const projectForm = useForm(defaultState, { entity: { ...defaultState, ...project }, requiredFields, validateOn: 'change' }, [
    project.id,
    project.cache_key,
  ])
  const initialValue = Object.keys(project).length === 0 ? defaultState : cloneDeep(projectForm.entityState)
  return {
    ...projectForm,
    initialValue,
  }
}

const useProject = (initProject = {}) => {
  const { entity: project } = useLatestEntity(initProject, 'projects')

  const dispatch = useThunkDispatch()

  const { callbacks } = useContext(PageContext)
  const { showProjectModal } = callbacks || {}

  const { creating, deleting, loading, updating } = useSelector((reduxState) => reduxState.projects)

  return {
    project,
    callbacks: {
      createProject: (params, requestOptions) => createProject(params, dispatch, requestOptions),
      updateProject: (params, requestOptions) => updateProject(params, dispatch, requestOptions),
      createOrEditProject: (params) => createOrEditProject(params || project, dispatch, showProjectModal),
      archiveProject: (params, requestOptions) => archiveProject(params, dispatch, requestOptions),
    },
    creating,
    deleting,
    loading,
    updating,
  }
}

export default useProject
