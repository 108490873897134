import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import useCurrentUser from 'hooks/useCurrentUser'
import MainRoutes from 'routes/MainRoutes'
import RedirectRoutes from 'routes/RedirectRoutes'

import PlaceholderPage from 'views/PlaceholderPage'

const AuthorizationController = () => {
  const { currentUser, errors } = useCurrentUser()

  if (errors?.length) {
    return <PlaceholderPage message={errors[0]} />
  }

  if (currentUser) {
    if (!currentUser.roles?.some((x) => ['Agent', 'Production_Manager', 'Back_Office', 'QC', 'Editor', 'Client_Admin'].includes(x))) {
      return <PlaceholderPage message={'You do not have access to this page.'} />
    }

    return <RouterProvider router={createBrowserRouter([MainRoutes, RedirectRoutes])} />
  }

  return <PlaceholderPage message={'Loading...'} />
}

export default AuthorizationController
