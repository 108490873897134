/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'

// material-ui
import { Box, Checkbox, FormControl, FormControlLabel, MenuItem, OutlinedInput, Select, Stack, Switch, Typography } from '@mui/material'

// project imports
import useSetState from 'hooks/useSetState'
import GoogleMapPlaces from 'ui-component/GoogleMapPlaces'

// ==============================|| ADDRESS COMPONENT ||============================== //

const defaultState = {
  address: '',
  addressState: '',
  error: '',
  inputManually: false,
  placeId: '',
  postcode: '',
  suburb: '',
}

const AddressComponent = (props) => {
  const {
    callbacks: { toggleSetValue },
    part: { detail },
  } = props

  const [state, setState] = useSetState(defaultState)
  const { address, addressState, error, inputManually, placeId, postcode, suburb } = state

  const { states } = useSelector((reduxState) => reduxState.entities)

  useEffect(() => {
    setState({
      address: detail?.value?.address || '',
      addressState: detail?.value?.state || '',
      inputManually: (detail?.value?.postcode && !detail?.value?.place_id) || inputManually,
      placeId: detail?.value?.place_id || '',
      postcode: detail?.value?.postcode || '',
      suburb: detail?.value?.suburb || '',
    })
  }, [detail?.value, detail])

  useEffect(() => {
    const debouncedUpdateValue = debounce(updateAddress, 500)
    debouncedUpdateValue(address)

    return () => debouncedUpdateValue.cancel()
  }, [address])

  useEffect(() => {
    const debouncedUpdateValue = debounce(updateAddress, 500)
    debouncedUpdateValue(addressState)

    return () => debouncedUpdateValue.cancel()
  }, [addressState])

  useEffect(() => {
    const debouncedUpdateValue = debounce(updateAddress, 500)
    debouncedUpdateValue(postcode)

    return () => debouncedUpdateValue.cancel()
  }, [postcode])

  useEffect(() => {
    const debouncedUpdateValue = debounce(updateAddress, 500)
    debouncedUpdateValue(suburb)

    return () => debouncedUpdateValue.cancel()
  }, [suburb])

  const updateAddress = () => {
    let updatedAddress = {
      address: address,
      suburb: suburb,
      state: addressState,
      postcode: postcode,
    }

    const errorMessage = !Object.values(updatedAddress).every((x) => [null, ''].includes(x))
      ? Object.values(updatedAddress).some((x) => [null, ''].includes(x))
        ? 'Address is not valid'
        : ''
      : ''

    const isEmpty = Object.values(updatedAddress).every((x) => [null, ''].includes(x))

    setState({ error: errorMessage })

    if (placeId) updatedAddress = { ...updatedAddress, place_id: placeId }

    toggleSetValue(updatedAddress, errorMessage, isEmpty)
  }

  const fillAddress = (value, details) => {
    setState({ address: '', suburb: '', addressState: '', placeId: '', postcode: '' })

    if (!value) return console.log('none')

    setState({ address: value?.structured_formatting?.main_text || '', placeId: value?.place_id })

    for (const address of details?.address_components) {
      const componentType = address.types[0]
      switch (componentType) {
        // case 'subpremise':
        //   subpremise = `${address.long_name}/`
        //   setState({ address: subpremise + address1 })
        //   break

        // case 'street_number':
        //   address1 = `${subpremise}${address.long_name} ${address1}`
        //   setState({ address: address1 })
        //   break

        // case 'route':
        //   address1 += address.short_name
        //   setState({ address: address1 })
        //   break

        case 'postal_code':
          setState({ postcode: `${address.long_name}` })
          break

        case 'postal_code_suffix':
          console.log('postal_code_suffix', address.long_name)
          break

        case 'locality':
          setState({ suburb: address.long_name })
          break

        case 'administrative_area_level_1':
          setState({ addressState: address.short_name })
          break

        case 'country':
          console.log('country', address.long_name)
          break
      }
    }
  }

  return (
    <Box noValidate>
      {inputManually ? (
        <Stack spacing={1}>
          <OutlinedInput onChange={(e) => setState({ address: e.target.value, placeId: '' })} placeholder="Address" value={address} />
          <OutlinedInput onChange={(e) => setState({ suburb: e.target.value, placeId: '' })} placeholder="Suburb" value={suburb} />
          <Stack direction="row" spacing={1}>
            <FormControl fullWidth>
              <Select
                defaultValue=""
                displayEmpty
                onChange={(e) => setState({ addressState: e.target.value, placeId: '' })}
                value={addressState}
                variant="outlined"
              >
                <MenuItem value="">Please select...</MenuItem>
                {Object.values(states).map((addState) => (
                  <MenuItem key={addState.value} value={addState.value}>
                    {addState.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <OutlinedInput
              onChange={(e) => setState({ postcode: e.target.value, placeId: '' })}
              placeholder="Postcode"
              value={postcode}
              fullWidth
              required
            />
          </Stack>
        </Stack>
      ) : (
        <GoogleMapPlaces onAddressChange={fillAddress} selectedValue={state} />
      )}
      {error ? (
        <Typography variant="subtitle2" color="error" padding={0.5}>
          {error}
        </Typography>
      ) : null}
      <FormControlLabel
        value="end"
        control={<Checkbox checked={inputManually} onChange={() => setState({ inputManually: !inputManually })} size="small" />}
        label="Input Address Manually"
        labelPlacement="end"
      />
    </Box>
  )
}

AddressComponent.propTypes = {
  callbacks: PropTypes.object,
  part: PropTypes.object,
}

export default AddressComponent
