import { lazy } from 'react'

// project imports
import Loadable from 'ui-component/Loadable'
import ErrorElement from 'routes/ErrorElement'

// dashboard routing
const RedirectPage = Loadable(lazy(() => import('views/Redirect')))

// ==============================|| REDIRECT ROUTING ||============================== //

const RedirectRoutes = {
  path: '/redirect/:platform',
  element: <RedirectPage />,
  errorElement: <ErrorElement />,
}

export default RedirectRoutes
