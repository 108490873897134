import { normalize } from 'normalizr'
import { Schemas } from 'redux/schema'
import { updateEntities, deleteEntity } from 'redux/entities'

import api from 'utils/api'

import { handleError } from '../utils'

const FETCH_REQUEST = 'contenthouse/favourite/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/favourite/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/favourite/FETCH_FAILURE'

const CREATE_REQUEST = 'contenthouse/favourite/CREATE_REQUEST'
const CREATE_SUCCESS = 'contenthouse/favourite/CREATE_SUCCESS'
const CREATE_FAILURE = 'contenthouse/favourite/CREATE_FAILURE'

const DELETE_REQUEST = 'contenthouse/favourite/DELETE_REQUEST'
const DELETE_SUCCESS = 'contenthouse/favourite/DELETE_SUCCESS'
const DELETE_FAILURE = 'contenthouse/favourite/DELETE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loading: false,
  result: {},
}

// Actions
export function fetchRequest() {
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(payload = {}) {
  return {
    type: FETCH_SUCCESS,
    payload,
  }
}

export function fetchFailure(errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function deleteRequest() {
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []) {
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadFavourites(options) {
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api('/users/favorites', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.FAVOURITE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess(data))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function createFavourite(favourite, options = {}) {
  const config = {
    method: 'POST',
    data: favourite,
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api(`/users/favorites`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.FAVOURITE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, result: data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function deleteFavourite(favourite) {
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/users/favorites/${favourite.id}`, { permanent: true }, config)
      .then(() => {
        const normalizedJson = normalize(favourite, Schemas.FAVOURITE)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true, result: favourite }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload, errors } = action

  switch (type) {
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
        result: payload.continuationToken,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors,
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors,
      }
    default:
      return state
  }
}
