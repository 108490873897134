/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

// material-ui
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material'

// project imports
import useSetState from 'hooks/useSetState'
import { formatToDollar, parseValueByType } from 'utils/utilities'

// ==============================|| CHECKLIST COMPONENT ||============================== //

const defaultState = {
  checked: [],
}

const ChecklistComponent = (props) => {
  const {
    callbacks: { toggleSetValue },
    clientFlags,
    part: { detail, price_tags },
  } = props

  const [state, setState] = useSetState(defaultState)
  const { checked } = state

  useEffect(() => {
    setState({ checked: detail?.value || [] })
    if (detail?.value && detail?.value !== '') {
      toggleSetValue(detail?.value)
    }
  }, [detail?.value, detail])

  const updateChecklist = (option) => {
    let updated = checked || []
    let value = parseValueByType(option.value, detail?.sub_type)

    if (!option.checked) {
      updated = updated.filter((x) => x !== value)
    } else {
      updated.push(value)
    }

    setState({ checked: updated })
    toggleSetValue(updated)
  }

  return (
    <Box noValidate>
      <FormControl fullWidth>
        <FormGroup>
          {detail?.options.map((option, index) => {
            const price = price_tags?.find((price) => price?.option_index === index)

            return (
              <Stack
                key={option.value}
                direction="row"
                sx={{ alignItems: 'center', justifyContent: 'space-between', width: '100%' }}
                spacing={1}
              >
                <FormControlLabel
                  control={
                    <Checkbox checked={checked.includes(option?.value)} value={option?.value} onChange={(e) => updateChecklist(e.target)} />
                  }
                  label={option.name}
                />
                {!clientFlags.includes('HidePrice') && price && (
                  <Typography textAlign="end" sx={{ paddingRight: '13px' }}>
                    {formatToDollar(price.unit_price)}
                  </Typography>
                )}
              </Stack>
            )
          })}
        </FormGroup>
      </FormControl>
    </Box>
  )
}

ChecklistComponent.propTypes = {
  callbacks: PropTypes.object,
  clientFlags: PropTypes.array,
  part: PropTypes.object,
}

export default ChecklistComponent
