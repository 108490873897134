import { schema } from 'normalizr'

// Circular Dependencies - Use the define method
// const item = new schema.Entity('item')
// const child = new schema.Entity('child')
// item.define({ children: [child] })

// Define Entities
const asset = new schema.Entity('assets')
const favourite = new schema.Entity('favourites')
const job = new schema.Entity('jobs')
const merchandise = new schema.Entity('merchandises')
const office = new schema.Entity('offices')
const order = new schema.Entity('orders')
const orderItem = new schema.Entity('orderItems')
const part = new schema.Entity('parts')
const post = new schema.Entity('posts')
const price = new schema.Entity('prices')
const product = new schema.Entity('products')
const productPart = new schema.Entity('productParts')
const project = new schema.Entity('projects')
const projectFile = new schema.Entity('projectFiles')
const provider = new schema.Entity('providers')
const task = new schema.Entity('tasks')
const taskSheet = new schema.Entity('taskSheets')
const user = new schema.Entity('users')

// enums
const platformType = new schema.Entity(
  'platformTypes',
  {},
  {
    idAttribute: (value) => value.value,
  }
)

const merchandiseGroup = new schema.Entity(
  'merchandiseGroups',
  {},
  {
    idAttribute: (value) => value.value,
  }
)

const partType = new schema.Entity(
  'partTypes',
  {},
  {
    idAttribute: (value) => value.value,
  }
)

const partSubType = new schema.Entity(
  'partSubTypes',
  {},
  {
    idAttribute: (value) => value.value,
  }
)

const serviceType = new schema.Entity(
  'serviceTypes',
  {},
  {
    idAttribute: (value) => value.value,
  }
)

const userRole = new schema.Entity(
  'userRoles',
  {},
  {
    idAttribute: (value) => value.value,
  }
)

// unique enums
const state = new schema.Entity(
  'states',
  {},
  {
    idAttribute: (value) => value.value,
  }
)

// Export Schemas
export const Schemas = {
  ASSET: asset,
  ASSET_ARRAY: [asset],
  FAVOURITE: favourite,
  FAVOURITE_ARRAY: [favourite],
  JOB: job,
  JOB_ARRAY: [job],
  MERCHANDISE: merchandise,
  MERCHANDISE_ARRAY: [merchandise],
  OFFICE: office,
  OFFICE_ARRAY: [office],
  ORDER: order,
  ORDER_ARRAY: [order],
  ORDER_ITEM: orderItem,
  ORDER_ITEM_ARRAY: [orderItem],
  PART: part,
  PART_ARRAY: [part],
  POST: post,
  POST_ARRAY: [post],
  PRICE: price,
  PRICE_ARRAY: [price],
  PRODUCT: product,
  PRODUCT_ARRAY: [product],
  PRODUCT_PART: productPart,
  PRODUCT_PART_ARRAY: [productPart],
  PROJECT: project,
  PROJECT_ARRAY: [project],
  PROJECT_FILE: projectFile,
  PROJECT_FILE_ARRAY: [projectFile],
  PROVIDER: provider,
  PROVIDER_ARRAY: [provider],
  TASK: task,
  TASK_ARRAY: [task],
  TASK_SHEET: taskSheet,
  TASK_SHEET_ARRAY: [taskSheet],
  USER: user,
  USER_ARRAY: [user],
  // enums
  PLATFORM_TYPE: platformType,
  PLATFORM_TYPE_ARRAY: [platformType],
  MERCHANDISE_GROUP: merchandiseGroup,
  MERCHANDISE_GROUP_ARRAY: [merchandiseGroup],
  PART_SUB_TYPE: partSubType,
  PART_SUB_TYPE_ARRAY: [partSubType],
  PART_TYPE: partType,
  PART_TYPE_ARRAY: [partType],
  SERVICE_TYPE: serviceType,
  SERVICE_TYPE_ARRAY: [serviceType],
  USER_ROLE: userRole,
  USER_ROLE_ARRAY: [userRole],
  // unique enums
  STATE: state,
  STATE_ARRAY: [state],
}

export default Schemas
