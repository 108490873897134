import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'

const general = {
  id: 'general',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Home',
      type: 'item',
      url: '/',
      icon: HomeOutlinedIcon,
      breadcrumbs: false,
    },
    {
      id: 'profile',
      title: 'Profile',
      type: 'item',
      url: '/profile',
      icon: PersonOutlineOutlinedIcon,
      breadcrumbs: false,
    },
  ],
}

export default general
